import axiosInstance from './axiosInstance';

const getCryptoNetworks = (filters = {}) => {
  const { perPage, page } = filters;

  return axiosInstance.get('/internal/admin/crypto_networks', {
    params: {
      per_page: perPage,
      page,
    },
  });
};

export default getCryptoNetworks;
