import * as Yup from 'yup';

export const SERVICES_LIST = [
  {
    label: 'Cash In',
    value: 'cash-in',
  },
  {
    label: 'Cash Out',
    value: 'cash-out',
  },
  {
    label: 'Recarga de tarjeta (externa)',
    value: 'charge_card_external',
  },
  {
    label: 'Recarga de tarjeta (interna)',
    value: 'charge_card_internal',
  },
  {
    label: 'SWAP',
    value: 'swap',
  },
  {
    label: 'Retiros',
    value: 'withdrawal',
  },
];

export const CURRENCIES_LIST = [
  {
    label: 'USD',
    value: 'usd',
  },
];

export const FIELDS_NAME = {
  SERVICE_ID: 'serviceId',
  CURRENCY_ID: 'currencyId',
  MINIMUN_AMOUNT: 'minimunAmount',
  MAXIMUN_MONTHLY_AMOUNT: 'maximunMonthlyAmount',
  MAXIMUN_ANNUAL_AMOUNT: 'maximunAnnualAmount',
};

export const initialValues = {
  [FIELDS_NAME.SERVICE_ID]: '',
  [FIELDS_NAME.CURRENCY_ID]: '',
  [FIELDS_NAME.MINIMUN_AMOUNT]: '',
  [FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT]: '',
  [FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.SERVICE_ID]: Yup.string().required('Campo Requerido'),
  [FIELDS_NAME.CURRENCY_ID]: Yup.string().required('Campo Requerido'),
  [FIELDS_NAME.MINIMUN_AMOUNT]: Yup.string().required('Campo Requerido'),
  [FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT]: Yup.string().required(
    'Campo Requerido',
  ),
  [FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT]: Yup.string().required('Campo Requerido'),
});

export const formatInputAmount = (e) => {
  const exceptThisSymbols = ['e', 'E', '+', '-', ','];
  return exceptThisSymbols.includes(e.key) && e.preventDefault();
};
