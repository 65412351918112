import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { useLazyRequest } from '../../hooks';
import { reactivateInvoice } from '../../api';

const ReactivateInvoiceButton = ({ resourceType, resourceId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [
    ,
    loadingReactivateInvocie,
    ,
    executeReactivateInvoice,
  ] = useLazyRequest({
    request: reactivateInvoice,
    withPostSuccess: () => {
      enqueueSnackbar(
        'El estado del pago será actualizado durante los próximos minutos',
        { variant: 'success' },
      );
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'Ha ocurrido un error, intententelo nuevamente mas tarde',
        { variant: 'error' },
      );
    },
  });

  const handleReactivate = useCallback(() => {
    executeReactivateInvoice({
      resourceType,
      resourceId,
    });
  }, [executeReactivateInvoice, resourceType, resourceId]);

  return (
    <ButtonWithCirculaProgress
      variant="contained"
      color="default"
      handleButtonClick={handleReactivate}
      loading={loadingReactivateInvocie}
      label="Reactivar pago"
      gutters={false}
    />
  );
};

ReactivateInvoiceButton.propTypes = {
  resourceType: PropTypes.oneOf([
    'kriptostore',
    'kriptopayment',
    'payment',
    'operation',
    'deposit',
  ]).isRequired,
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default ReactivateInvoiceButton;
