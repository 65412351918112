import * as Yup from 'yup';

export const FIELDS_NAME = {
  NAME: 'name',
  FEE: 'fee',
  FOCUSED_TO_CREATE: 'focusedToCreate',
  FOCUSED_TO_PAY: 'focusedToPay',
  FOCUSED_TO_WITHDRAWALS: 'focusedToWithdrawals',
  FOCUSED_TO_DEPOSIT: 'focusedToDeposit',
};

export const getInitialValues = (data) => ({
  [FIELDS_NAME.NAME]: data?.name ?? '',
  [FIELDS_NAME.FEE]: data?.fee ?? '',
  [FIELDS_NAME.FOCUSED_TO_CREATE]: data?.focused_to_create ?? false,
  [FIELDS_NAME.FOCUSED_TO_PAY]: data?.focused_to_pay ?? false,
  [FIELDS_NAME.FOCUSED_TO_WITHDRAWALS]: data?.focused_to_withdrawals ?? false,
  [FIELDS_NAME.FOCUSED_TO_DEPOSIT]: data?.focused_to_deposit ?? false,
});

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.NAME]: Yup.string().required('Campo Requerido'),
  [FIELDS_NAME.FEE]: Yup.string().required('Campo Requerido'),
  [FIELDS_NAME.FOCUSED_TO_CREATE]: Yup.boolean(),
  [FIELDS_NAME.FOCUSED_TO_PAY]: Yup.boolean(),
  [FIELDS_NAME.FOCUSED_TO_WITHDRAWALS]: Yup.boolean(),
  [FIELDS_NAME.FOCUSED_TO_DEPOSIT]: Yup.boolean(),
});

export const formatInputAmount = (e) => {
  const pressKeyNumber = !/[0-9]/.test(e.key);
  const pressAcceptedEspecialKey = ![
    '.',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
  ].includes(e.key);

  const isDobleDot = e.key === '.' && e.target.value.includes('.');

  return (
    ((pressKeyNumber && pressAcceptedEspecialKey) || isDobleDot) &&
    e.preventDefault()
  );
};
