import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Avatar,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useFormik } from 'formik';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { hasError, getHelperTextField } from '../../utils';

import {
  FIELDS_NAME,
  getInitialValues,
  validationSchema,
  formatInputAmount,
} from './utils';

const CryptoNetworkForm = ({ data, onSubmit }) => {
  const {
    values,
    errors,
    touched,
    getFieldProps,
    submitForm,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: getInitialValues(data),
    validationSchema,
    onSubmit,
  });

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          Datos de la cripto red
        </Typography>
      </Grid>

      <Grid item container spacing={3}>
        <Grid item xs={4}>
          <TextField
            label="Moneda"
            defaultValue={data?.currency_name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    alt={data?.currency_name}
                    src={data?.currency_icon}
                    style={{ width: 30, height: 30 }}
                  />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            disabled
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label="Red"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    alt={data?.name}
                    src={data?.icon}
                    style={{ width: 30, height: 30 }}
                  />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            error={hasError(errors, touched, FIELDS_NAME.NAME)}
            helperText={getHelperTextField(errors, touched, FIELDS_NAME.NAME)}
            {...getFieldProps(FIELDS_NAME.NAME)}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label="Comision"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            onKeyDown={formatInputAmount}
            error={hasError(errors, touched, FIELDS_NAME.FEE)}
            helperText={getHelperTextField(errors, touched, FIELDS_NAME.FEE)}
            {...getFieldProps(FIELDS_NAME.FEE)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" color="primary">
          Datos de habilitación
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                {...getFieldProps(FIELDS_NAME.FOCUSED_TO_CREATE)}
                checked={values[FIELDS_NAME.FOCUSED_TO_CREATE]}
                color="primary"
              />
            }
            label="Wallet"
          />

          <FormControlLabel
            control={
              <Switch
                {...getFieldProps(FIELDS_NAME.FOCUSED_TO_PAY)}
                checked={values[FIELDS_NAME.FOCUSED_TO_PAY]}
                color="primary"
              />
            }
            label="Pagos"
          />

          <FormControlLabel
            control={
              <Switch
                {...getFieldProps(FIELDS_NAME.FOCUSED_TO_WITHDRAWALS)}
                checked={values[FIELDS_NAME.FOCUSED_TO_WITHDRAWALS]}
                color="primary"
              />
            }
            label="Retiros"
          />

          <FormControlLabel
            control={
              <Switch
                {...getFieldProps(FIELDS_NAME.FOCUSED_TO_DEPOSIT)}
                checked={values[FIELDS_NAME.FOCUSED_TO_DEPOSIT]}
                color="primary"
              />
            }
            label="Deposito"
          />
        </FormGroup>
      </Grid>

      <Grid item container spacing={3} justify="center">
        <Grid item xs="auto">
          <ButtonWithCirculaProgress
            variant="outlined"
            color="primary"
            label="Guardar"
            gutters={false}
            handleButtonClick={submitForm}
            loading={isSubmitting}
            disabled={!dirty}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

CryptoNetworkForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    currency_id: PropTypes.string,
    name: PropTypes.string,
    fee: PropTypes.string,
    focused_to_create: PropTypes.bool,
    focused_to_pay: PropTypes.bool,
    focused_to_withdrawals: PropTypes.bool,
    focused_to_deposit: PropTypes.bool,
    icon: PropTypes.string,
    currency_icon: PropTypes.string,
    currency_name: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

CryptoNetworkForm.defaultProps = {
  data: {},
};

export default CryptoNetworkForm;
