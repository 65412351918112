import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircle as ActiveIcon,
  Cancel as NoActiveIcon,
} from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';

const ActiveDot = ({ isActive, fontSize }) => {
  if (!isActive)
    return <NoActiveIcon style={{ color: red[500] }} fontSize={fontSize} />;

  return <ActiveIcon style={{ color: green[500] }} fontSize={fontSize} />;
};

ActiveDot.propTypes = {
  isActive: PropTypes.bool,
  fontSize: PropTypes.oneOf(['large', 'medium', 'small']),
};

ActiveDot.defaultProps = {
  isActive: false,
  fontSize: 'small',
};

export default ActiveDot;
