import React, { lazy, Suspense, useCallback, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { CssBaseline, ThemeProvider, IconButton } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { Close as CloseIcon } from '@material-ui/icons';

import NestingRoute from './routes/NestingRoute';
import PrivateRoute from './routes/PrivateRoute';
import { CircularProgress } from './components';
import { theme } from './utils';

const PageNotFound = lazy(() => import('./routes/pageNotFound'));
const LoginPage = lazy(() => import('./routes/login'));
// Clients Page Group
const clientsListPage = lazy(() => import('./routes/clients/list'));
const clientShowPage = lazy(() => import('./routes/clients/show'));
// Cash In Page Group
const CashInListPage = lazy(() => import('./routes/cashIn/list'));
const CashInShowPage = lazy(() => import('./routes/cashIn/show'));
const CashInEditPage = lazy(() => import('./routes/cashIn/edit'));
// Cash Out Page Group
const CashOutListPage = lazy(() => import('./routes/cashOut/list'));
const CashOutShowPage = lazy(() => import('./routes/cashOut/show'));
const CashOutEditPage = lazy(() => import('./routes/cashOut/edit'));
// Swaps Page Group
const SwapsListPage = lazy(() => import('./routes/swaps/list'));
const SwapShowPage = lazy(() => import('./routes/swaps/show'));
const SwapEditPage = lazy(() => import('./routes/swaps/edit'));
// Quotations Page Group
const QuotationsCreatePage = lazy(() => import('./routes/quotations/create'));
// Payments
const PaymentsListPage = lazy(() => import('./routes/payments/list'));
const PaymentShowPage = lazy(() => import('./routes/payments/show'));
// Merchats
const MerchantsListPage = lazy(() => import('./routes/merchants/list'));
const MerchantsShowPage = lazy(() => import('./routes/merchants/view'));
// Retirements
const RetirementsListPage = lazy(() => import('./routes/retirements/list'));
const RetirementShowPage = lazy(() => import('./routes/retirements/show'));
// Tasks
const TasksListPage = lazy(() => import('./routes/tasks/list'));
// KriptoStore
const KriptoStoreListPage = lazy(() => import('./routes/kriptostore/list'));
const KriptoStoreViewPage = lazy(() => import('./routes/kriptostore/view'));
const KriptoStoreEditPage = lazy(() => import('./routes/kriptostore/edit'));
// KriptoPayment
const KriptoPaymentListPage = lazy(() => import('./routes/kriptopayment/list'));
const KriptoPaymentShowPage = lazy(() => import('./routes/kriptopayment/show'));
const KriptoPaymentEditPage = lazy(() => import('./routes/kriptopayment/edit'));
// Banks group page
const BanksListPage = lazy(() => import('./routes/banks/list'));
const BankCreatePage = lazy(() => import('./routes/banks/create'));
const BankShowPage = lazy(() => import('./routes/banks/show'));
const BankEditPage = lazy(() => import('./routes/banks/edit'));

// Carousels
const CarouselsPage = lazy(() => import('./routes/carousels'));

// Reports
const ReportsCreatePage = lazy(() => import('./routes/reports/create'));
const ReportHistoryPage = lazy(() => import('./routes/reports/history'));

// Wallets Payment
const WalletsPaymentListPage = lazy(() =>
  import('./routes/walletsPayment/list'),
);

// Devolutions
const DevolutionsList = lazy(() => import('./routes/devolutions/list'));
const DevolutionsCreate = lazy(() => import('./routes/devolutions/create'));
const DevolutionsView = lazy(() => import('./routes/devolutions/show'));
const DevolutionsEdit = lazy(() => import('./routes/devolutions/edit'));

// Services Config
const ServicesConfigList = lazy(() => import('./routes/servicesConfig/list'));
const ServicesConfigItem = lazy(() =>
  import('./routes/servicesConfig/serviceItem'),
);
const ServicesConfigAdd = lazy(() => import('./routes/servicesConfig/add'));

// Dashboard
const DashboardPage = lazy(() => import('./routes/dashboard'));

// Rewards
const RewardsList = lazy(() => import('./routes/rewards/list'));
const RewardsCreate = lazy(() => import('./routes/rewards/create'));

// Indexers
const IndexersList = lazy(() => import('./routes/indexers'));

// ToUp
const ToUpList = lazy(() => import('./routes/toUp/list'));
const ToUpShow = lazy(() => import('./routes/toUp/show'));

// CryptoNetworks
const CryptoNetworksList = lazy(() => import('./routes/cryptoNetworks/list'));
const CryptoNetworkShow = lazy(() => import('./routes/cryptoNetworks/show'));
const CryptoNetworkEdit = lazy(() => import('./routes/cryptoNetworks/edit'));

// CryptoNetworks
const DepositsList = lazy(() => import('./routes/deposits/list'));

const hideDurationSnackbars = 5000; // 5 segundos

const App = () => {
  const notistackRef = useRef(null);

  const handleCloseSnackbar = useCallback(
    (key) => () => {
      notistackRef.current.closeSnackbar(key);
    },
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={hideDurationSnackbars}
          preventDuplicate
          action={(keySnackbar) => (
            <IconButton size="small" onClick={handleCloseSnackbar(keySnackbar)}>
              <CloseIcon />
            </IconButton>
          )}
        >
          <div>
            <CssBaseline />
            <Router>
              <Suspense
                fallback={<CircularProgress style={{ minHeight: '100vh' }} />}
              >
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={() => <Redirect to="/login" />}
                  />

                  <Route exact path="/login" component={LoginPage} />

                  <NestingRoute
                    path="/clients"
                    component={clientsListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: clientShowPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/merchants"
                    component={MerchantsListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: MerchantsShowPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/cash-in"
                    component={CashInListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: CashInShowPage,
                        exact: true,
                      },
                      {
                        path: '/:id/edit',
                        component: CashInEditPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/cash-out"
                    component={CashOutListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: CashOutShowPage,
                        exact: true,
                      },
                      {
                        path: '/:id/edit',
                        component: CashOutEditPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/swap"
                    component={SwapsListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: SwapShowPage,
                        exact: true,
                      },
                      {
                        path: '/:id/edit',
                        component: SwapEditPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <PrivateRoute
                    path="/quotations/create"
                    component={QuotationsCreatePage}
                    exact
                  />

                  <NestingRoute
                    path="/payments"
                    component={PaymentsListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: PaymentShowPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/retirements"
                    component={RetirementsListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: RetirementShowPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/kriptostore"
                    component={KriptoStoreListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: KriptoStoreViewPage,
                        exact: true,
                      },
                      {
                        path: '/:id/edit',
                        component: KriptoStoreEditPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <PrivateRoute path="/tasks" component={TasksListPage} exact />

                  <NestingRoute
                    path="/kriptopayment"
                    component={KriptoPaymentListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: KriptoPaymentShowPage,
                        exact: true,
                      },
                      {
                        path: '/:id/edit',
                        component: KriptoPaymentEditPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/banks"
                    component={BanksListPage}
                    isPrivate
                    nesting={[
                      {
                        path: '/create',
                        component: BankCreatePage,
                        exact: true,
                      },
                      {
                        path: '/:id',
                        component: BankShowPage,
                        exact: true,
                      },
                      {
                        path: '/:id/edit',
                        component: BankEditPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/devolutions"
                    component={DevolutionsList}
                    isPrivate
                    nesting={[
                      {
                        path: '/create',
                        component: DevolutionsCreate,
                        exact: true,
                      },
                      {
                        path: '/:id',
                        component: DevolutionsView,
                        exact: true,
                      },
                      {
                        path: '/:id/edit',
                        component: DevolutionsEdit,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <PrivateRoute
                    exact
                    path="/carousels"
                    component={CarouselsPage}
                  />

                  <NestingRoute
                    path="/reports"
                    component={ReportsCreatePage}
                    isPrivate
                    nesting={[
                      {
                        path: '/history',
                        component: ReportHistoryPage,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <PrivateRoute
                    path="/wallets-payment"
                    component={WalletsPaymentListPage}
                    exact
                  />

                  <NestingRoute
                    path="/services-config"
                    component={ServicesConfigList}
                    isPrivate
                    nesting={[
                      {
                        path: '/add',
                        component: ServicesConfigAdd,
                        exact: true,
                      },
                      {
                        path: '/:service',
                        component: ServicesConfigItem,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <PrivateRoute
                    path="/dashboard"
                    component={DashboardPage}
                    exact
                  />

                  <NestingRoute
                    path="/rewards"
                    component={RewardsList}
                    isPrivate
                    nesting={[
                      {
                        path: '/create',
                        component: RewardsCreate,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <PrivateRoute
                    path="/indexers"
                    component={IndexersList}
                    exact
                  />

                  <NestingRoute
                    path="/to-up"
                    component={ToUpList}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: ToUpShow,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <NestingRoute
                    path="/crypto-networks"
                    component={CryptoNetworksList}
                    isPrivate
                    nesting={[
                      {
                        path: '/:id',
                        component: CryptoNetworkShow,
                        exact: true,
                      },
                      {
                        path: '/:id/edit',
                        component: CryptoNetworkEdit,
                        exact: true,
                      },
                      {
                        path: '*',
                        component: PageNotFound,
                        exact: false,
                      },
                    ]}
                  />

                  <PrivateRoute
                    path="/deposits"
                    component={DepositsList}
                    exact
                  />

                  <Route path="*" component={PageNotFound} />
                </Switch>
              </Suspense>
            </Router>
          </div>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
