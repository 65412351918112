import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Avatar, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const CurrencyInfo = ({ name, icon }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Avatar alt={name} src={icon} style={{ width: 30, height: 30 }} />

      <Typography variant="body2">{name}</Typography>
    </Box>
  );
};

CurrencyInfo.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default CurrencyInfo;
