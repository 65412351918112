import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Drawer,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  PeopleAlt as ClientsIcon,
  LocalAtm as QuotationIcon,
  Business as BusinessIcon,
  Atm as RetirementsIcon,
  Assignment as TasksIcon,
  SwapHoriz as SwapsIcon,
  Storefront as StoreIcon,
  Receipt as KriptoPaymentIcon,
  AccountBalance as BanksIcon,
  ViewCarousel as ViewCarouselIcon,
  AttachMoney,
  SettingsApplications as ConfigServices,
  BarChart as DashboardIcon,
  Grade as RewardsIcon,
  VerticalAlignTop as ToUpIcon,
  DeviceHub as CryptoNetworkIcon,
} from '@material-ui/icons';

import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { includes } from 'lodash';

import {
  CashIn as CashInIcon,
  CashOut as CashOutIcon,
  CreditCard as CreditCardIcon,
  Reports as ReportsIcon,
  WalletsPayment as WalletsPaymentIcon,
  NetworkWired as NetworkWiredIcon,
  Deposit as DepositIcon,
} from '../Icons';

import styles from './styles';

const useStyles = makeStyles(styles);

const LateralMenu = ({ openMenu, handleCloseMenu }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const routeMatch = (path) => includes(pathname, path);

  return (
    <Drawer
      variant="permanent"
      classes={{
        root: classNames(classes.drawer, {
          [classes.drawerOpen]: openMenu,
          [classes.drawerClose]: !openMenu,
        }),
        paper: classNames({
          [classes.drawerOpen]: openMenu,
          [classes.drawerClose]: !openMenu,
        }),
      }}
    >
      <Toolbar classes={{ root: classes.toolbar }}>
        <IconButton onClick={handleCloseMenu}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <List className={classes.listContainer}>
        <ListItem
          button
          component={Link}
          to="/clients"
          selected={routeMatch('/clients')}
        >
          <ListItemIcon>
            <ClientsIcon />
          </ListItemIcon>
          <ListItemText primary="Clientes" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/merchants"
          selected={routeMatch('/merchants')}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Merchants" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/cash-in"
          selected={routeMatch('/cash-in')}
        >
          <ListItemIcon>
            <CashInIcon />
          </ListItemIcon>
          <ListItemText primary="Cash In" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/cash-out"
          selected={routeMatch('/cash-out')}
        >
          <ListItemIcon>
            <CashOutIcon />
          </ListItemIcon>
          <ListItemText primary="Cash Out" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/swap"
          selected={routeMatch('/swap')}
        >
          <ListItemIcon>
            <SwapsIcon />
          </ListItemIcon>
          <ListItemText primary="Swaps" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/payments"
          selected={routeMatch('/payments')}
        >
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary="Payments" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/quotations/create"
          selected={routeMatch('/quotations/create')}
        >
          <ListItemIcon>
            <QuotationIcon />
          </ListItemIcon>
          <ListItemText primary="Crear Cotización" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/retirements"
          selected={routeMatch('/retirements')}
        >
          <ListItemIcon>
            <RetirementsIcon />
          </ListItemIcon>
          <ListItemText primary="Retiros" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/tasks"
          selected={routeMatch('/tasks')}
        >
          <ListItemIcon>
            <TasksIcon />
          </ListItemIcon>
          <ListItemText primary="Tareas" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/kriptostore"
          selected={routeMatch('/kriptostore')}
        >
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="Kriptostore" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/kriptopayment"
          selected={routeMatch('/kriptopayment')}
        >
          <ListItemIcon>
            <KriptoPaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Kriptopayment" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/banks"
          selected={routeMatch('/banks')}
        >
          <ListItemIcon>
            <BanksIcon />
          </ListItemIcon>
          <ListItemText primary="Bancos" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/devolutions"
          selected={routeMatch('/devolutions')}
        >
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText primary="Devoluciones" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/carousels"
          selected={routeMatch('/carousels')}
        >
          <ListItemIcon>
            <ViewCarouselIcon />
          </ListItemIcon>
          <ListItemText primary="Carruseles" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/reports"
          selected={routeMatch('/reports')}
        >
          <ListItemIcon>
            <ReportsIcon />
          </ListItemIcon>
          <ListItemText primary="Reportes" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/wallets-payment"
          selected={routeMatch('/wallets-payment')}
        >
          <ListItemIcon>
            <WalletsPaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Wallets de pago" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/services-config"
          selected={routeMatch('/services-config')}
        >
          <ListItemIcon>
            <ConfigServices />
          </ListItemIcon>
          <ListItemText primary="Conf. Services" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/dashboard"
          selected={routeMatch('/dashboard')}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/rewards"
          selected={routeMatch('/rewards')}
        >
          <ListItemIcon>
            <RewardsIcon />
          </ListItemIcon>
          <ListItemText primary="Rewards" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/indexers"
          selected={routeMatch('/indexers')}
        >
          <ListItemIcon>
            <NetworkWiredIcon />
          </ListItemIcon>
          <ListItemText primary="Indexers" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/to-up"
          selected={routeMatch('/to-up')}
        >
          <ListItemIcon>
            <ToUpIcon />
          </ListItemIcon>
          <ListItemText primary="Recargas" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/crypto-networks"
          selected={routeMatch('/crypto-networks')}
        >
          <ListItemIcon>
            <CryptoNetworkIcon />
          </ListItemIcon>
          <ListItemText primary="Cripto Redes" />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/deposits"
          selected={routeMatch('/deposits')}
        >
          <ListItemIcon>
            <DepositIcon />
          </ListItemIcon>
          <ListItemText primary="Depositos" />
        </ListItem>
      </List>
    </Drawer>
  );
};

LateralMenu.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
};

export default LateralMenu;
