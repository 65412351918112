import axiosInstance from './axiosInstance';

const getDeposits = (filters = {}) => {
  const { perPage, page } = filters;

  return axiosInstance.get('/internal/admin/deposits', {
    params: {
      per_page: perPage,
      page,
    },
  });
};

export default getDeposits;
