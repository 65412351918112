import axiosInstance from './axiosInstance';

const updateCryptoNetwork = (params) => {
  const { cryptoNetwokId, data } = params;

  return axiosInstance.patch(
    `/internal/admin/crypto_networks/${cryptoNetwokId}`,
    data,
  );
};

export default updateCryptoNetwork;
